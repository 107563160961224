<template lang="pug">
v-card.my-5(flat, max-width="400px")
  v-toolbar(flat, dense)
    .subtitle-2 Touch a Biz Account
  v-card-text
    v-combobox(:items="bizs", v-model="selectedBiz", v-if="bizs", dense)
  v-card-actions
    v-btn(block, color="secondary", @click="touch()") Touch
</template>
<script>
import _ from "underscore";

export default {
  data: () => ({
    bizs: [],
    selectedBiz: "",
  }),
  mounted() {
    this.loadBizs();
  },
  methods: {
    async loadBizs() {
      const params = { criteria: {}, select: "name" };
      const result = await this.$api.biz.list(params);
      this.bizs = _.chain(result)
        .sortBy("name")
        .map((o) => {
          return { value: o._id, text: o.name };
        })
        .value();
    },
    async touch() {
      const bizId = this.selectedBiz?.value;
      if (!bizId) return;
      try {
        await this.$api.bizAccount.custom("touch", { bizId });
        this.$toast.success("Biz account touched");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
